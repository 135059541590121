<template>
  <div class="c-trade-now__home" v-if='isHome'>
    <div class="c-trade-now__home-body" :class="{'c-trade-now__body_outside': outsideContent == true , 'c-trade-now__body_margin-top-30': personal == true }" >
      <div class="c-trade-now__body-title">
        <h1 class="c-trade-now__body-title-text c-trade-now__body-title-text-center">Trade Now</h1>
      </div>
      <div class="c-trade-now__body-form">
        <input class="c-trade-now__body-form-input" v-model="input.email" placeholder="Email Address"/>
        <div class = "c-trade-now__password-container">
          <input :type = "showPassword?'text':'password'" class="c-trade-now__body-form-input c-trade-now__body-form-input_relative" v-model="input.password" placeholder="Password"/>
          <img src="@/assets/images/show-password.svg" class = "c-trade-now__password-show" v-if = "showPassword" @click = "togglePassword">
          <img src="@/assets/images/hide-password.svg" class = "c-trade-now__password-show" v-if = "!showPassword" @click = "togglePassword">
        </div>
        <div class="c-trade-now__body-form-option">
          <p class="c-trade-now__body-form-option-text" @click="forgotPassword">Forgot Password</p>
          <p class="c-trade-now__body-form-option-text" @click="registerAccount">Register</p>
        </div>
        <div class="c-trade-now__body-form-button c-trade-now__body-form-button-center">
          <button class="c-trade-now__body-form-button-submit" :class="{'c-trade-now__body-form-button-submit_disable': !validInput}" :disabled="!validInput" @click="executeGeetest">Sign In</button>
        </div>
      </div>
      <Geetest ref="geetest" @verify="authenticate"></Geetest>
      <InvalidCaptchaAlert v-if = "invalidCaptchaStatus"></InvalidCaptchaAlert>
    </div>
  </div>



  <div class="c-trade-now__body" :class="{'c-trade-now__body_outside': outsideContent == true , 'c-trade-now__body_margin-top-30': personal == true }" v-else>
    <div class="c-trade-now__body-title">
      <h1 class="c-trade-now__body-title-text">Trade Now</h1>
    </div>
    <div class="c-trade-now__body-form">
      <input class="c-trade-now__body-form-input" v-model="input.email" placeholder="Email Address"/>
      <div class = "c-trade-now__password-container">
        <input :type = "showPassword?'text':'password'" class="c-trade-now__body-form-input c-trade-now__body-form-input_relative" v-model="input.password" placeholder="Password"/>
        <img src="@/assets/images/show-password.svg" class = "c-trade-now__password-show" v-if = "showPassword" @click = "togglePassword">
        <img src="@/assets/images/hide-password.svg" class = "c-trade-now__password-show" v-if = "!showPassword" @click = "togglePassword">
      </div>
      <div class="c-trade-now__body-form-option">
        <p class="c-trade-now__body-form-option-text" @click="forgotPassword">Forgot Password</p>
        <p class="c-trade-now__body-form-option-text" @click="registerAccount">Register</p>
      </div>
      <div class="c-trade-now__body-form-button">
        <button class="c-trade-now__body-form-button-submit" :class="{'c-trade-now__body-form-button-submit_disable': !validInput}" :disabled="!validInput" @click="executeGeetest">Sign In</button>
      </div>
    </div>
    <Geetest ref="geetest" @verify="authenticate"></Geetest>
    <InvalidCaptchaAlert v-if = "invalidCaptchaStatus"></InvalidCaptchaAlert>
  </div>
</template>

<script>
import InvalidCaptchaAlert from '@/components/InvalidCaptchaAlert'
import Geetest from '@/components/Geetest'
import publicKey from 'raw-loader!../../public/key/sparrowSecurityAuthPub.key'
  export default {
    name: 'TradeNow',
    components: {Geetest, InvalidCaptchaAlert},
    props: {
      outsideContent: Boolean,
      personal: Boolean
    },
    data() {
      return {
        input:{
          email: '',
          password: '',
          t:Date.now()
        },
        forge: require('node-forge'),
        processing: false,
        maintenance:false,
        publicKey:publicKey,
        pubKey:null,
        captchaRes:'',
        invalidCaptchaStatus: false,
        errorText:'',
        showPassword:false,
        isHome: false,
      }
    },
    created() {
      this.$eventHub.$on('invalidCaptchaStatus', (status) =>{
        this.invalidCaptchaStatus = status
      });
    },
    mounted(){
      this.pubKey = this.forge.pki.publicKeyFromPem(this.publicKey);
      this.checkRoute();
    },
    methods: {
      checkRoute() {
        if (this.$route.name == 'Home') {
          this.isHome = true;
        }else {
          this.isHome = false;
        }
      },
      registerAccount() {
        this.$router.push('/signup').catch(() => {})
        this.$eventHub.$emit('show-hide-risk', true);
      },
      forgotPassword() {
        this.$router.push('/forgot-password').catch(() => {})
      },
      checkMaintenance() {
        this.$http.get(this.createApiUrlPricing('get_platform_status')).then(response => {
          var apistatus = response.body.api_status
          var pricingstatus = response.body.pricing_status
          if(apistatus=="0" || pricingstatus=="0") {
            this.maintenance = true
            this.$router.push('/maintenance').catch(() => {})
          } else {
            this.maintenance = false
          }
        }, err => {
          console.log(err)
          // this.showMessageError(err, false , err.status)
        })
      },
      encryptInput:function(){
        this.input.t = Date.now();
        const enc = this.symEncrypt(this.input);
        const key = this.pubKey.encrypt(enc.key, 'RSA-OAEP', {
          mgf1: {
            md: this.forge.md.sha1.create(),
          },
        });
        const iv = this.pubKey.encrypt(enc.iv, 'RSA-OAEP', {
          mgf1: {
            md: this.forge.md.sha1.create(),
          },
        });
        return {
          ciphertext: this.forge.util.encode64(enc.ciphertext),
          key: this.forge.util.encode64(key),
          iv: this.forge.util.encode64(iv),
        };
      },
      symEncrypt:function(dt){
        const key = this.forge.random.getBytesSync(32);
        const iv = this.forge.random.getBytesSync(16);
        const encCipher = this.forge.aes.createEncryptionCipher(key, 'CBC');
        encCipher.start(iv);
        encCipher.update(this.forge.util.createBuffer(JSON.stringify(dt), 'utf-8'));
        encCipher.finish();
        const ciphertext = encCipher.output.data;
        return {
          key,
          iv,
          ciphertext,
        };
      },
      executeGeetest:function(){
        if(this.processing){
          return false
        }
        this.processing = true;
        this.$refs.geetest.execute()
      },
      authenticate: function(captchaRes){
        var encryptedInput = this.encryptInput();
        this.captchaRes = captchaRes
        let _this = this
        // this.$http.post("https://api2.sparrowsandbox.com/users/login?g-recaptcha-response="+ captchaRes, encryptedInput).then(response =>{

        this.$http.post(this.createApiUrlChestNut("users/login?geetest_challenge="+captchaRes.geetest_challenge+"&geetest_seccode="+captchaRes.geetest_seccode+"&geetest_validate="+captchaRes.geetest_validate), encryptedInput).then(response =>{
          localStorage.setItem('vuex', JSON.stringify({'token': response.body.accessToken.token}));
          this.$store.commit('doLogin',response.body.accessToken)
          this.errorText = ''
          this.$http.get(this.createApiUrlChestNut('me')).then(me =>{
            localStorage.setItem('email', me.email);
            localStorage.setItem('ethAmout', me.ethAmout);
            localStorage.setItem('ethAddress', me.ethAddress);
            localStorage.setItem('kycStatus', me.kycStatus);
            localStorage.setItem('amlStatus', me.amlStatus);
            localStorage.setItem('userKYCStatus', me.userKYCStatus);
            localStorage.setItem('addressVerificationStatus', me.addressVerificationStatus);

            if (me.body.userKYCStatus === 'UNSUBMITTED') {
                this.$router.push("/registration-process").catch(() => {});
              } else {
                if (localStorage.getItem('apiKeyURL') === null) {
                  window.location = '/dashboard/#/checker'
                } else {
                  let url = localStorage.getItem('apiKeyURL')
                  window.location = url
                }
              }

          })
        },err => {
          _this.$refs.geetest.reset()
          this.checkMaintenance()
          if(this.maintenance) {
            return false
          } else {
            if(err.body.code=='OTPRequired'){
              this.processing = false
              this.$router.push({ name: 'Signin Twofa', params: {
                email: this.input.email,
                password: this.input.password,
                captchaRes: this.captchaRes
              }}).catch(() => {})
            }
            else if(err.body.code == 'DeviceAuthRequired'){
              this.processing = false
              this.$router.push("/unrecognized").catch(() => {});
            }else if (err.body.code == 'IncorrectEmailPassword') {
              this.$eventHub.$emit('show-alert', true, {title: 'Oops, something went wrong.', body: 'Email / password is incorrect'}, false)
              this.processing = false
            }
            else{
              if(err.status == 0) {
                this.$eventHub.$emit('show-alert', true, {title: 'Oops, something went wrong.', body: 'Too many requests received, please try again shortly.'}, false)
                this.processing = false
              } else {
                this.$eventHub.$emit('show-alert', true, {title: 'Oops, something went wrong.', body: err.body.message}, false)
                this.processing = false
              }
            }
          }
        });
      },
      togglePassword:function(){
        this.showPassword = !this.showPassword
      }
    },
    computed:{
      validInput:function(){
        return this.input.email && this.input.password
      },
    },
    watch: {

    }
}
</script>

<style lang="css" scoped>
</style>
